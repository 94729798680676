<template>

  <div id="filemanagerdiv">
    <!-- basic modal -->
    <b-modal
      id="modal-1"
      ref="mail-send-modal"
      title="Dosya Mail Gönder"
      size="xl"
      hide-footer
    >
      <mail-send :files="selectedFiles" />
    </b-modal>
    <vue-context ref="foldermenu">
      <template slot-scope="child">
        <li v-if="$can('read','document_edit')">
          <b-link
            class="d-flex align-items-center"
            @click="addSubFolderClick(child.data)"
          >
            <feather-icon
              icon="PlusSquareIcon"
              size="16"
            />
            <span class="ml-75">{{ $t('Alt Klasör Ekle') }}</span>
          </b-link>
          <b-link
            class="d-flex align-items-center"
            @click="folderEditClicked(child.data)"
          >
            <feather-icon
              icon="EditIcon"
              size="16"
            />
            <span class="ml-75">{{ $t('Düzenle') }}</span>
          </b-link>
          <b-link
            class="d-flex align-items-center"
            variant="danger"
            @click="deleteFolderClick(child.data)"
          >
            <feather-icon
              icon="Trash2Icon"
              size="16"
            />
            <span class="ml-75">{{ $t('Sil') }}</span>
          </b-link>
        </li>
      </template>
    </vue-context>
    <vue-context ref="filemenu">
      <template slot-scope="child,index">
        <li>
          <b-link
            class="d-flex align-items-center"
            @click="downloadFileClick(child.data)"
          >
            <feather-icon
              icon="DownloadIcon"
              size="16"
            />
            <span class="ml-75">{{ $t('İndir') }}</span>
          </b-link>
          <b-link
            v-if="$can('read','document_edit')"
            class="d-flex align-items-center"
            @click="changeFileNameClick(child.data)"
          >
            <feather-icon
              icon="Edit3Icon"
              size="16"
            />
            <span class="ml-75">{{ $t('Yeniden Adlandır') }}</span>
          </b-link>
          <b-link
            v-if="$can('read','document_edit')"
            class="d-flex align-items-center"
            variant="danger"
            @click="deleteFileClick(child.data,index)"
          >
            <feather-icon
              icon="Trash2Icon"
              size="16"
            />
            <span class="ml-75">{{ $t('Sil') }}</span>
          </b-link>
        </li>
      </template>
    </vue-context>
    <!--============ Folders Modal =====================================================-->
    <b-modal
      id="folder-modal"
      ref="folder-modal"
      centered
      :title="folderData.id==0?'Yeni Klasör Ekle':'Klasör Düzenle'"
      hide-footer
    >

      <!-- BODY -->
      <validation-observer

        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(saveData)"
          @reset.prevent="resetForm"
        >

          <!-- Firma ad -->
          <validation-provider
            #default="validationContext"
            :name="$t('Klasör Adı')"
            rules="required|min:2"
          >
            <b-form-group
              :label="$t('Klasör Adı')"
              label-for="name"
            >
              <b-form-input
                id="name"
                v-model="folderData.name"
                autofocus
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <b-form-group
            :label="$t('Erişebilen Roller')"
            label-for="selected_roles"
          >
            <v-select
              id="selected_roles"
              v-model="folderData.roles"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              multiple
              :options="roleOptions"
              :reduce="val => val.value"
              transition=""
              :clearable="false"
              input-id="selected_roles"
            />
          </b-form-group>
          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              {{ $t('Kaydet') }}
            </b-button>
          </div>

        </b-form>
      </validation-observer>
    </b-modal>
    <!--========================================== files modal ===================================================================================================-->
    <b-modal
      id="file-modal"
      ref="file-modal"
      centered
      :title="fileData.id==0?'Yeni Dosya Ekle':'Dosya Adı Düzenle'"
      hide-footer
    >
      <div
        v-if="uploadIsProgress"
        class="progress-wrapper"
      >
        <b-card-text class="mb-0">
          <span> {{ fileData.selected_files[lastFileIndex].name }}</span>
          {{ $t('Dosya Yükleniyor. Lütfen Bekleyiniz...') }} {{ uploadProgress+'%' }}
        </b-card-text>
        <b-progress
          v-model="uploadProgress"
          max="100"
        />
      </div>
      <!-- BODY -->
      <validation-observer
        v-if="!uploadIsProgress"
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(saveFileData)"
          @reset.prevent="resetForm"
        >

          <div v-if="fileData.id==0">
            <div
              v-for="(sfile, index) in fileData.selected_files"
              :key="index"
            >
              <span>Dosya: <strong>{{ sfile.name }} </strong></span> <span>Boyut: <strong> {{ $staticParams.toHumanFileSize(sfile.size,true) }}</strong></span></div></div>
          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              {{ $t('Kaydet') }}
            </b-button>
          </div>

        </b-form>
      </validation-observer>
    </b-modal>
    <b-row>
      <b-col md="12">
        <b-card
          class="mb-0"
        >

          <b-row>
            <b-col md="4">
              <b-button
                v-if="$can('read','document_edit')"
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                v-b-modal.folder-modal
                variant="outline-primary"
                pill
                @click="resetFolderData"
              >  <feather-icon

                icon="PlusIcon"
                class="mr-50"
              />
                Klasör Oluştur
              </b-button>
              <hr>
              <category-tree
                :data="categoryData"
                :selecteddata="selectedFolderData"
                :refs="$refs"
                @selected="categorySelectHandler"
              />
            </b-col>
            <b-col md="8">
              <b-alert
                v-if="selectedFolderData.id==0"
                variant="primary"
                show
              >
                <div class="alert-body">
                  <span><strong>  {{ $t('Dosyaları Görüntülemek ve Dosya Yükleyebilmek İçin Klasör Seçiniz') }}</strong></span>
                </div>
              </b-alert>

              <b-overlay
                v-if="selectedFolderData.id!=0"
                :show="filesOverlay"
                rounded="sm"
              >
                <b-form-file
                  v-if="$can('read','document_edit')"
                  id="file_path"
                  v-model="fileData.selected_files"
                  :placeholder="$t('Bir dosya seçiniz veya buraya sürükleyiniz')"
                  :drop-placeholder="$t('Dosyayı buraya bırakınız...')"
                  size="lg"
                  multiple
                  @change="onFileChange"
                />
                <hr>
                <b-button
                  v-if="selectedFiles.length>0"
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-primary"
                  size="sm"
                  @click="openMailSendModal()"
                >
                  Seçilenleri E-Posta Gönder
                </b-button>
                <hr>
                <b-table-simple
                  hover
                  selectable
                >
                  <b-thead>
                    <b-tr>
                      <b-th />
                      <b-th sticky-column>
                        {{ $t('Dosya Adı') }}
                      </b-th>
                      <b-th>
                        {{ $t('Dosya Boyutu') }}
                      </b-th>
                    </b-tr>
                  </b-thead>
                  <b-tbody>
                    <b-tr
                      v-for="(item, index) in filesData"
                      :key="index"
                      @contextmenu.prevent="$refs.filemenu.open($event,item,index)"
                    >
                      <b-td>
                        <b-form-checkbox
                          v-model="item.selected"
                          :value="true"
                          :unchecked-value="false"
                        />
                      </b-td>
                      <b-td>
                        <b-link @click="downloadFileClick(item)">
                          {{ item.name+'.'+item.type }}
                        </b-link>
                      </b-td>
                      <b-td>
                        {{ $staticParams.toHumanFileSize(item.size,true) }}
                      </b-td>
                    </b-tr>
                  </b-tbody>

                </b-table-simple>
              </b-overlay>
            </b-col>
          </b-row>

        </b-card>
      </b-col>

    </b-row>

  </div>
</template>

<script>
// eslint-disable-next-line
import 'quill/dist/quill.core.css'
// eslint-disable-next-line
import 'quill/dist/quill.snow.css'
// eslint-disable-next-line
import 'quill/dist/quill.bubble.css'
import {
  BCard, BRow, BCol, VBModal, BFormCheckbox, BButton, BModal, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BTableSimple, BTr, BTd, BTh, BTbody, BThead, BFormFile, BProgress, BCardText, BOverlay, BAlert, BLink,
} from 'bootstrap-vue'
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import { required } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import axios from '@axios'
import vueContext from 'vue-context'
import vSelect from 'vue-select'
import useJwt from '@/auth/jwt/useJwt'
import filesStoreModule from './fileStoreModule'
import categoryTree from './CategoryTree.vue'
import mailSend from './email/EmailSend.vue'

export default {
  components: {
    BCard,
    BModal,
    BAlert,
    mailSend,
    vSelect,
    BFormFile,
    BFormCheckbox,
    BOverlay,
    vueContext,
    BForm,
    BLink,
    BTableSimple,
    BTr,
    BTd,
    BTh,
    BTbody,
    BProgress,
    BCardText,
    BThead,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    categoryTree,
    BRow,
    BCol,
    BButton,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  data() {
    return {
      uploadProgress: 0,
      filesOverlay: false,
      uploadIsProgress: false,
      lastFileIndex: 0,
      categoryData: [
      ],
      required,
      showMarkAsReturnedModal: false,
      returnCompletionDate: '',
      showEditSidebar: false,
    }
  },
  computed: {
    selectedFiles() {
      const selectedFiles = []
      this.filesData.forEach(val => {
        if (val.selected) {
          selectedFiles.push(val)
        }
      })
      return selectedFiles
    },
  },
  created() {
  },
  mounted() {
    this.fetchCategories(0)
  },
  methods: {
    openMailSendModal() {
      this.$refs['mail-send-modal'].show()
    },
    changeFileNameClick(item) {
      this.fileData = JSON.parse(JSON.stringify(item))
      this.$refs['file-modal'].show()
    },
    deleteFileClick(item, index) {
      this.deleteFile(`${item.name}.${item.type}`, item.id, index)
    },
    downloadFileClick(item) {
      const excelUrl = `${process.env.VUE_APP_API_URL}/api/files-download?file_num=${item.id}&token=${useJwt.getToken()}`
      window.location.href = `${excelUrl}`
    },
    folderEditClicked(item) {
      this.folderData = JSON.parse(JSON.stringify(item))
      this.folderDataOriginal = item
      this.$refs['folder-modal'].show()
    },
    addSubFolderClick(item) {
      this.resetFolderData()
      this.folderData.parent_id = item.id
      this.$refs['folder-modal'].show()
    },
    deleteFolderClick(item) {
      this.deleteFolder(item.name, item.id)
    },
    onFileChange() {
      // this.resetFileData()
      this.fileData.category_id = this.selectedFolderData.id
      this.$refs['file-modal'].show()
    },
    categorySelectHandler(item) {
      this.selectedFolderData = item
      this.fileData.category_id = item.id
      this.fetchFiles()
    },
    // eslint-disable-next-line no-unused-vars
    saveData() {
      if (this.folderData.id === 0) {
        store.dispatch('app-files/addCategory', this.folderData)
          .then(() => {
            this.fetchCategories(0)
            this.resetFolderData()
            this.showSuccessMessage()
            this.$refs['folder-modal'].hide()
          })
          .catch(() => {
            this.showErrorMessage()
          })
      } else {
        store.dispatch('app-files/updateCategory', this.folderData)
          .then(() => {
            this.fetchCategories(0)
            // = response.data.data
            this.resetFolderData()
            this.showSuccessMessage()
            this.$refs['folder-modal'].hide()
          })
          .catch(() => {
            this.showErrorMessage()
          })
      }
    },
    uploadFiles(fileIndex) {
      const formData = new FormData()
      this.uploadIsProgress = true
      this.uploadProgress = 0
      formData.append('file', this.fileData.selected_files[fileIndex])
      formData.append('category_id', this.fileData.category_id)
      formData.append('name', this.fileData.selected_files[fileIndex].name)
      const config = {
        onUploadProgress: function (progressEvent) {
          this.uploadProgress = Math.round((progressEvent.loaded * 100) / progressEvent.total)
        }.bind(this),
      }
      axios
        .post('/api/files', formData, config)
        .then(response => {
          this.$bvToast.toast(this.$t('Yükleme İşlemi Tamamlandı'), {
            title: this.$t('İşlem Başarılı'),
            variant: 'success',
            solid: false,
          })
          this.$refs['file-modal'].hide()
          this.filesData.unshift(response.data.data)
          this.resetFileData()
          this.fileData.category_id = this.selectedFolderData.id
        })
        .catch(() => {
          this.showErrorMessage()
        })
        .finally(() => {
          this.uploadIsProgress = false
          this.uploadProgress = 0
        })
    },
    saveFileData() {
      if (this.fileData.id === 0) {
        this.fileData.selected_files.forEach((val, index) => {
          this.lastFileIndex = index
          this.uploadFiles(index)
        })
      } else {
        store.dispatch('app-files/updateFileName', { id: this.fileData.id, name: this.fileData.name })
          .then(() => {
            this.filesData.forEach(val => {
              if (val.id === this.fileData.id) {
                // eslint-disable-next-line no-param-reassign
                val.name = this.fileData.name
              }
            })
            this.$refs['file-modal'].hide()
            this.resetFileData()
            this.showSuccessMessage()
          })
          .catch(() => {
            this.showErrorMessage()
          })
      }
    },
    // eslint-disable-next-line no-unused-vars
    deleteFolder(folderName, id) {
      this.$swal({
        title: this.$t('Emin Misiniz ?'),
        text: this.$t(`${folderName} isimli  klasörü sildiğinizde klasöre ait tüm alt klasörler ve alt klasörler de dahil olmak üzere  klasör içerisinde bulunan tüm belgeler kalıcı olarak silinecek ve bu işlem geri alınamaz.`),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$t('Silme İşlemini Onayla'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          store.dispatch('app-files/deleteCategory', { id })
            .then(() => {
              this.fetchCategories(0)
              this.showSuccessMessage()
            })
            .catch(() => {
              this.showErrorMessage()
            })
        }
      })
    },
    deleteFile(fileName, id, index) {
      this.$swal({
        title: this.$t('Emin Misiniz ?'),
        text: this.$t(`${fileName} isimli  dosyayı silmek üzeresiniz. dosya tamamen silinecek ve  silme işlemi geri alınamaz.`),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$t('Silme İşlemini Onayla'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          store.dispatch('app-files/deleteFile', { id })
            .then(() => {
              this.filesData.splice(index, 1)
              this.showSuccessMessage()
            })
            .catch(() => {
              this.showErrorMessage()
            })
        }
      })
    },
    showSuccessMessage() {
      this.$bvToast.toast(this.$t('İşlem Başarılı'), {
        title: this.$t('İşlem Başarılı'),
        variant: 'success',
        solid: false,
      })
    },
    showErrorMessage() {
      this.$bvToast.toast(this.$t('İşlem Başarısız'), {
        title: this.$t('İşlem Başarısız'),
        variant: 'danger',
        solid: false,
      })
    },
    fetchCategories(parentCategory) {
      store.dispatch('app-files/fetchFileCategories', { parent_category: parentCategory })
        .then(response => {
          this.categoryData = response.data.data
        })
        .catch(() => {
          this.showErrorMessage()
        })
    },
    fetchFiles() {
      this.filesOverlay = true
      store.dispatch('app-files/fetchFiles', { category_id: this.selectedFolderData.id, list_type: 'all' })
        .then(response => {
          response.data.data.forEach(val => {
            // eslint-disable-next-line no-param-reassign
            val.selected = false
            // eslint-disable-next-line no-param-reassign
            val.download_url = `${process.env.VUE_APP_API_URL}/api/files-download?file_num=${val.id}&token=${useJwt.getToken()}`
          })
          this.filesData = response.data.data
        })
        .catch(() => {
          this.showErrorMessage()
        })
        .finally(() => {
          this.filesOverlay = false
        })
    },
  },
  setup() {
    const APP_STORE_MODULE_NAME = 'app-files'

    // Register module
    if (!store.hasModule(APP_STORE_MODULE_NAME)) store.registerModule(APP_STORE_MODULE_NAME, filesStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(APP_STORE_MODULE_NAME)) store.unregisterModule(APP_STORE_MODULE_NAME)
    })

    const blankFolderData = {
      name: '',
      id: 0,
      parent_id: 0,
    }
    const blankFileData = {
      name: '',
      id: 0,
      category_id: 0,
      selected_files: [],
    }
    const editMode = ref('add')
    const selectedFolderData = ref({ id: 0 })
    const folderData = ref(null)
    const folderDataOriginal = ref(null)
    const filesData = ref([])
    const fileData = ref(null)
    const roleOptions = ref([])
    store.dispatch('app/fetchRoles', {})
      .then(response => {
        const arr = [{ label: 'Tümü Erişebilir', value: 0 }]
        response.data.data.forEach(val => {
          arr.push({ label: val.name, value: val.id })
        })
        roleOptions.value = arr
      })
    const resetFolderData = () => {
      folderData.value = JSON.parse(JSON.stringify(blankFolderData))
    }
    resetFolderData()
    const resetFileData = () => {
      fileData.value = JSON.parse(JSON.stringify(blankFileData))
      fileData.value.category_id = selectedFolderData.value.id
    }
    resetFileData()
    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetFolderData)
    return {
      refFormObserver,
      getValidationState,
      resetFolderData,
      resetForm,
      selectedFolderData,
      fileData,
      editMode,
      folderData,
      folderDataOriginal,
      roleOptions,
      resetFileData,
      filesData,
      snowOption: {
        theme: 'snow',
      },
    }
  },
}
</script>

<style scoped>
.per-page-selector {
  width: 90px;
}
.list-group-item {
  transition: all 1s
}
#add-new-role-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
<style lang="scss" >
@import '@core/scss/vue/libs/vue-context.scss';
</style>
