import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {

    // Files --------------------
    addCategory(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/files-categories', data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateCategory(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/api/files-categories/${data.id}`, data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteCategory(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/api/files-categories/${data.id}`, data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchFileCategories(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/files-categories', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteFile(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/api/files/${data.id}`, data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchFiles(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/files', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateFileName(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/api/files/${data.id}`, data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchMailContent() {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/files-mail-content')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    sendMail(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/files-mail-send', data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

  },
}
