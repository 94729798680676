<template>
  <div>

    <b-list-group flush>
      <b-list-group-item
        v-for="(item, index) in data"
        :key="index"
        class="d-flex"
        style="border:none;"
      >
        <b-row>
          <b-col
            md="12"
          >
            <span
              class="mr-1"
              @click="item.sub?(item.is_open=!item.is_open):(true)"
            >

              <feather-icon
                :icon="item.is_open?'FolderMinusIcon':item.sub?'FolderPlusIcon':'FolderIcon'"
                size="20"
                variant="primary"
              />

            </span>
            <b-link
              @click="dataSelected(item)"
              @contextmenu.prevent="refs.foldermenu.open($event,item)"
            >
              <span v-if="selecteddata.id!=item.id">{{ item.name }}</span>
              <span v-if="selecteddata.id==item.id"><strong>{{ item.name }}</strong></span>
            </b-link>
          </b-col>
          <b-col
            v-if="item.is_open"
            md="12"
          >
            <category-tree
              :data="item.sub"
              :selecteddata="selecteddata"
              :refs="refs"
              @selected="dataSelectChild"
            />
          </b-col>
        </b-row>
      </b-list-group-item>

    </b-list-group>
  </div>
</template>

<script>
// eslint-disable-next-line
import 'quill/dist/quill.core.css'
// eslint-disable-next-line
import 'quill/dist/quill.snow.css'
// eslint-disable-next-line
import 'quill/dist/quill.bubble.css'
import {
  BRow, BCol, VBModal, BListGroup, BListGroupItem, BLink,
} from 'bootstrap-vue'
import { required } from '@validations'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BRow,
    BCol,
    BLink,
    // eslint-disable-next-line import/no-self-import
    categoryTree: () => import('./CategoryTree.vue'),
    BListGroup,
    BListGroupItem,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  props: {
    data: {
      type: Array,
      required: true,
    },
    selecteddata: {
      type: Object,
      required: true,
    },
    refs: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      required,
    }
  },
  mounted() {

  },
  methods: {
    dataSelectChild(item) {
      this.$emit('selected', item)
    },
    dataSelected(item) {
      this.$emit('selected', item)
    },
  },
  setup() {
  },
}
</script>

<style scoped>

.per-page-selector {
  width: 90px;
}
.list-group-item {
  transition: all 1s
}
#add-new-role-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
